<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="flex succesuss w-full">
    <div slot="no-body">
      <div class="justify-center items-center w-full">
        <div class="d-theme-dark-bg w-full" style="border-radius: 15px">
          <div class="p-5">
            <div class="logo">
              <img
                width="200"
                height="50"
                class="TextLogo"
                alt=""
                src="@/assets/images/logo.png"
              />
            </div>
           <div style="color:black">
             <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
               <strong>1. Introduction.</strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>

 The terms and conditions for this website contained in this webpage, property of Cure Journey Portal, a civil company, with license nº   966764 and address in: Souk Al Bahar, Saha offices, office 202, Dubai, UAE, are meant to govern the use of the website, and every page within the website (the collective term used for all the web pages is Website). The terms are enforceable and influence the manner in which the website is used. By accepting to use the website, you particularly accept all the detailed terms and conditions outlined here. This website must not be used if you protest any one of the terms and conditions outlined herein. This website cannot be used by minors (minors, in this case, mean people who have not attained the age of 18 years). Anyone who is a minor is prohibited from accessing the website.
&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
  <strong style="">What are website&rsquo; standard terms and conditions?&nbsp;</strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
  The terms and conditions for this website have been articulated in the form of a list of rules created with an intention of setting a basis under which the business relationship can be established and for the purpose of regulating the website’s repetitive actions. &nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:35.4pt;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
  <strong>1. Intellectual Property Rights.&nbsp;</strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
 Except for the content which you have voluntarily chosen to include in the website Medical Tourism Business under the stipulated rules, the licensor has full ownership of all the materials and intellectual property contained in the website, and every right has been reserved. All users have only been given limited license for the content dependent on the terms restrictions, for viewing material inside the website. Panda Tip: If and where the content in the website has been designed for viewership, then the user can pick it up from the last point above. Having said that, you will probably be required to offer more with respect to descriptive language concerning what the user is allowed to use our website for.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:35.4pt;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
  <strong>2. Restrictions.</strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
  &nbsp; Website users are categorically and particularly delimited from:</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
  &nbsp;1) Publication of any of the materials in this website on any media&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
  2) Commercializing, sublicensing, or selling any material from this website&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
  3) Utilizing the website in such a way that might damage it&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
  4) Showing and/or performing any of the website material in the public</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
  &nbsp;5) Use of the website in such a way that influences access to the website by other users</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
  &nbsp;6) Using the website in a manner that contravenes regulations and applicable laws, or a manner that might harm or inflict harm on the website or any business or person.&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
  7) Use of the website for the purpose of engaging in marketing or advertisement.&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
  8) Performing any data harvesting, data mining, data extraction or such like activities on the website or during the use of this website.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
  Registration on this website is prohibited for users less than 18 years old. Some of the areas in this website Medical Tourism Business have been
  restricted from users and further restrictions may be imposed on accessing other website areas at the discretion of the website owner. Any password
  and user identification owned for accessing this website is confidential and such information must be held in confidence.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:35.4pt;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><strong>
  . Your Content&nbsp;</strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
  In accordance with the terms and conditions of this website, the content for the user means any video, audio, images, text, or any other materials
   the users opt to display from the website. With regard to the content displayed by users, the user grants the owner a worldwide, non-exclusive,
    royalty-free, irrevocable, sub-licensable license for the use, publishing, adaptation, translation, reproduction, and distribution of such content
     to any media.&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
  The content displayed by users has to be their own and should never trespass the rights of any third parties. Medical Tourism Business holds the
  rights to delete any user content from the website at their discretion without giving notice, and for their own reasons.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:35.4pt;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
  <strong>5. No warranties.&nbsp;</strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>The website has been provided in its current form, with all the faults and there is no implied or express warranty representation from Medical Tourism Business in any form in relation to the website or contained materials. In addition, no information contained on the website should be taken to mean that advice or consultancy is provided to the user.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:35.4pt;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><strong>&nbsp;4. Limitation of liability.</strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>&nbsp; Under no circumstances shall the website Medical Tourism Business or any of the employees, directors and officers be held liable to the website user for any situation emanating from the use of the website or from the legal contracts sampled in any way, whether or not such a liability falls under tort, the contract or any other situation. Medical Tourism Business as well as the directors, employees, and officers will not be held accountable for their indirect, special or consequential liability emanating from anything associated with the website’s use.&rsquo;s use.&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:35.4pt;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><strong>5. Indemnification.</strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>&nbsp; The user fully insures Medical Tourism Business against and from any liabilities, causes, demands, damages, expenses, and costs emanating from or in relation to any violation of the terms provided (including the attorney fees deemed reasonable).&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:35.4pt;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><strong>6. Severability.</strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>&nbsp;In case any provision in the terms is deemed invalid or unenforceable under any of the applicable laws, then such invalidity or unenforceability will not cause the terms invalid or unenforceable in totality. In addition, the invalid and unenforceable provision will be removed without influencing the other provisions in any way.&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:35.4pt;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><strong>7. Variation of Terms.</strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>&nbsp;The website Medical Tourism Business holders are allowed to introduce revisions to the terms as they wish and as they deem fit. By accepting to use the website there is an expectation that you will read the terms regularly in order to make sure that all the terms and conditions guiding the website&rsquo;s use are understood.&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:35.4pt;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><strong>8. Assignment.</strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>The website Medical Tourism Business has permission to transfer, subcontract, and assign its obligations and rights under the stipulated terms without having to get consent or to issue a notification. The website users are, however, not allowed to transfer, subcontract, or assign any of the obligations and/or rights under the terms.&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:35.4pt;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><strong>9. Entire Agreement.</strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>&nbsp;All the terms, with the inclusion of disclaimers and legal notices in the website, make up the wholesome contract between the user and or Medical Tourism Business with regard to the website&rsquo;s use. The terms are superior to any prior understandings and agreements with regard to the website.&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:35.4pt;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><strong>10. Payment terms and conditions</strong></p>
<ul style="list-style-type: disc;">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>We accept payments online using VISA &amp; MASTER CARD credit/debit cards in USD</li>
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>The cardholder must retain a copy of the transaction records and &ldquo;Cure Journey Portal&rdquo; policies and rules.&nbsp;</li>
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>User is responsible for maintaining the confidentiality of his account.</li>
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>All credit/debit cards&rsquo; details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties.</li>
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>If you make a payment for our products or services on our website, the details you are asked to submit will be provided directly to our payment provider via a secured connection.&nbsp;</li>
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>&ldquo;Cure Journey Portal&rdquo; will not pass any debit/credit card details to third parties.&nbsp;</li>
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>&ldquo;Cure Journey Portal&rdquo; takes appropriate steps to ensure data privacy and security including through various hardware and software methodologies. However, &ldquo;www.doclinia.com&rdquo; cannot guarantee the security of any information that is disclosed online.&nbsp;</li>
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>&ldquo;Cure Journey Portal&rdquo; is not responsible for the privacy policies of websites to which it links.</li>
</ul>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";'>If you provide any information to such third parties different rules regarding the collection and use of your personal information may apply. You should contact these entities directly if you have any questions about their use of the information that they collect.&nbsp;</p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
    <ul style="margin-bottom:0in;list-style-type: disc;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>Some of the advertisements you see on the Site are selected and delivered by third parties, such as ad networks, advertising agencies, advertisers, and audience segment providers. These third parties may collect information about you and your online activities, either on the Site or on other websites, through cookies, web beacons, and other technologies in an effort to understand your interests and deliver to you advertisements that are tailored to your interests. Please remember that we do not have access to, or control over, the information these third parties may collect. The information practices of these third parties are not covered by this privacy policy.&nbsp;</li>
    </ul>
</div>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:35.4pt;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><strong>11. Cancelation conditions</strong></p>
<ul style="list-style-type: disc;">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>The cancellation conditions are clearly illustrated on each service provided before the step of booking. The user is obliged to read carefully the cancellation policies and conditions.</li>
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>By proceeding with the booking and payment of the service, the user integrally agrees on the cancellation conditions and policies.</li>
</ul>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:35.4pt;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><strong>12. Refund Policy:</strong></p>
<ul style="list-style-type: disc;margin-left:61.400000000000006px;">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>Refund will be made onto the original mode of payment and will be processed within 10 to 45 days depends on the issuing bank of the credit card</li>
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>Deductions may arise from the refunded amount based on operational and paper works.</li>
</ul>

<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";'>&nbsp;</p>

<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:35.4pt;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><strong>
  13. Service delivery</strong></p>
<ul style="list-style-type: disc;">
    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
      Doclinia will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of UAE.</li>
</ul>

<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";'>&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:.0001pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri","sans-serif";'>&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:35.4pt;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'><strong>14. Governing law and jurisdiction</strong></p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>The terms for this website are construed and governed in conformity with UAE Laws. The website users must accept to be submitted to courts of Dubai and nonexclusive jurisdiction of UAE for the purpose of dispute resolution.&nbsp;</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
 UAE is the domicile country of Cure Journey Portal, a civil company in Dubai, with address in Souk Al Bahar, Saha offices, office 202, Dubai, UAE, and stipulates that the governing law is the local law</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
The outlines do not form any attorney-client arrangement between any entities or persons. Furthermore, the outline is available for all members under an agreement that the company isn’t concerned with providing any professional or legal advice. The outline can be altered without prior notice to members. The company will not be liable for omissions or errors or the manner in which the outline is interpreted or used or for any outcomes emanating from the indirect or direct use of the outline. Members are advised to consult appropriate professionals or personal lawyers for professional and legal advice.</p>
           </div>


            <div style="text-align: center">
              <vs-button
                style="width: 60%; margin-top: 2rem"
                @click="$router.go(-1)"
                >{{ $t("back") }}</vs-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    Message: {
      type: String,
      default: () => "",
    },
  },
  components: {},
  computed: {},
  created() {},
};
</script>

<style lang="scss">
.succesuss {
  width: 100%;
  margin: auto;
}
.logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  text-align: center;
}
.P-Text {
  color: #004477;
}
</style>


